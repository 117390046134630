import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { MapNavMenu } from '../Map/MapElements';
import NavDropdown from 'react-bootstrap/NavDropdown'
import authService from '../../utils/AuthenticationService';

export const SettingsDropdown = () => {

    const navigate = useNavigate()

    const [adminLink, setAdminLink] = useState(null)
    const [admin, setAdmin] = useState(null)

    async function getProfile(){
        let profile = await authService.getProfile()
        return profile
    }

    useEffect(() => {

        async function setValues(){
            let profile = await getProfile();

            setAdminLink(`https://auth.woodweather.net/t/${profile.organization_id}/Users/`)

            let roles = profile.role
            let admin = false

            for (let i=0; i < roles.length; i++){
                if (roles[i].includes('Admin')){
                    admin = true
                    break
                }
            }

            setAdmin(admin)
        }

        setValues()
    },[]);

  return (
    <MapNavMenu>
        <NavDropdown title="SETTINGS" id="basic-nav-dropdown" style={{ marginRight: '35px', color:'white', fontWeight: 'bold', fontSize: '15px'}}>
            <NavDropdown.Item onClick={() => navigate('/profile')}>Profile</NavDropdown.Item>
            {admin == true && <NavDropdown.Item href={adminLink} target="_blank"> Admin</NavDropdown.Item>}
            <NavDropdown.Item onClick={() => authService.logout()}>Logout</NavDropdown.Item>
        </NavDropdown>
    </MapNavMenu>
  )
}
