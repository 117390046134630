import styled from 'styled-components';
import { Link } from 'react-scroll';

export const Button = styled(Link)`
    border-radius: 2px;
    outline-color: #fff;
    outline-style: solid;
    outline-width: 2px;
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none !important;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: #010606;
        background: ${({primary}) => (primary ? '#fff' : '#01bf71')}; 
    }
`;