import React, { useRef, useEffect } from 'react';
import { Typography } from '@mui/material';
import { FaChevronRight, FaChevronLeft, FaExpand, FaRegBuilding, FaRegCalendarAlt, FaList, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';


const dropdown_checklist = {
    width: '100%',
    marginLeft: 'auto',
    '.MuiFormControlLabel-label': {
        fontSize: '.85rem',
        margin: '5px'
    },
    '.MuiSvgIcon-root': {
        fontSize: '1rem'
    },
    '.MuiButtonBase-root': {
        padding: '0px'
    }
}


const DiscoverDropdown = (props) => {

    const [dateDialogOpened, setDateDialogStatus] = React.useState(false);
    const [selectedVariables, updateSelectedVariables] = React.useState([]);
    const [selectedOrganizations, updateSelectedOrganizations] = React.useState([]);
    const [selectedTimeFrame, updateSelectedTimeframe] = React.useState([null,null]);
    const [variableTreeOptions, updateVariableOptions] = React.useState([]);

    const organizationOptions = props.orgOptions;
    const variableOptions = props.variableOptions;
    const variableTreeOpenLists = props.variableCollapsibles;

    const dropdownRef = useRef(null);
    const datepickerRef = useRef();
    datepickerRef.current = dateDialogOpened;

    DetectOutsideClick(dropdownRef); // used to detect mouse click outside of dropdown menu


    function calcHeight(el) {
        const height = el.offsetHeight;
        props.setMenuHeight(height);
    }

    const variableIsSelected = (variable) => {

        const variableSelected = selectedVariables.findIndex(filterVar => filterVar.toLowerCase() == variable) != -1 ? true : false;
        if (variableSelected) {
            return true;
        } else {
            return false;
        }
    }

    const organizationIsSelected = (organization) => {

        const orgSelected = selectedOrganizations.findIndex(org => org.id == organization.id) != -1 ? true : false;
        if (orgSelected) {
            return true;
        } else {
            return false;
        }
    }

    const getStartDate = () => {
        if (selectedTimeFrame.length > 0) {
            return selectedTimeFrame[0];
        } else {
            return null;
        }
    }

    const getEndDate = () => {
        if (selectedTimeFrame.length > 1) {
            return selectedTimeFrame[1];
        } else {
            return null;
        }
    }

    function setStartDate(newDate) {
        const currentlySelectedTimeframes = [...selectedTimeFrame];
        currentlySelectedTimeframes[0] = newDate;
        updateSelectedTimeframe(currentlySelectedTimeframes);
    }

    function setEndDate(newDate) {
        const currentlySelectedTimeframes = [...selectedTimeFrame];
        currentlySelectedTimeframes[1] = newDate;
        updateSelectedTimeframe(currentlySelectedTimeframes);
    }

    function updateFilteredVariables(selectedVariable) {

        const variableSelectedIndex = selectedVariables.findIndex(filterVar => filterVar == selectedVariable.id);
        const filteredVariables = [...selectedVariables]

        if (variableSelectedIndex != -1) {
            filteredVariables.splice(variableSelectedIndex, 1);
        } else {
            filteredVariables.push(selectedVariable.id);
        }

        updateSelectedVariables(filteredVariables);
        props.filterBy([selectedOrganizations, filteredVariables, selectedTimeFrame]);

    }

    function updateFilteredOrganizations(selectedOrganization) {

        const orgSelectedIndex = selectedOrganizations.findIndex(org => org.id == selectedOrganization.id);
        const filteredOrganizations = [...selectedOrganizations];

        if (orgSelectedIndex != -1) {
            filteredOrganizations.splice(orgSelectedIndex, 1);
        } else {
            filteredOrganizations.push(selectedOrganization);
        }

        updateSelectedOrganizations(filteredOrganizations);
        props.filterBy([filteredOrganizations, selectedVariables, selectedTimeFrame]);

    }

    function DetectOutsideClick(ref) {
        useEffect(() => {

          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && !datepickerRef.current) {
                props.dropdownActive(false);
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    function resetFilters() {
        updateSelectedVariables([]);
        updateSelectedOrganizations([]);
        updateSelectedTimeframe([null, null]);
        props.resetFilters();
        props.setMenuHeight(props.menuHeight - 30);
    }

    function fetchCurrentFilterOptions() {
        const filters = props.getCurrentFilters();
        updateSelectedOrganizations(filters[0]);
        updateSelectedVariables(filters[1]);
        updateSelectedTimeframe(filters[2]);
    }

    function constructVariableTree() {
        const meteorologyKeywords = ["wind", "dew", "humidity", "air", "visibility", "ensemble"];
        const iceKeywords = ["ice"];
        const oceanographyKeywords = ["sea", "wave", "period", "water", "salinity", "probe", "peake", "heave"];

        const variableOptionTrees = [
            {id: "meteorology", name: "Meteorology", children: []},
            {id: "oceanography", name: "Oceanography", children: []},
            {id: "ice", name: "Ice", children: [{ id: 'seaice', name: 'Sea Ice'}, { id: 'icebergs', name: 'Icebergs'}]}
        ];

        const allVariableOptions = variableOptions.sort();

        for (let i = 0; i < allVariableOptions.length; i++) {
            const variable = allVariableOptions[i];
            const node = { id: variable.toLowerCase(), name: variable};
            const underMeteorologyHeading = meteorologyKeywords.filter((keyword) => variable.toLowerCase().includes(keyword)).length > 0 ? true : false;
            const underOceanographyHeading = oceanographyKeywords.filter((keyword) => variable.toLowerCase().includes(keyword)).length > 0 ? true : false;
            const underIceHeading = iceKeywords.filter((keyword) => variable.toLowerCase().includes(keyword)).length > 0 ? true : false;

            if (underMeteorologyHeading) {
                variableOptionTrees[0].children.push(node);
            } else if (underIceHeading) {
                variableOptionTrees[2].children.push(node);
            } else if (underOceanographyHeading) {
                variableOptionTrees[1].children.push(node);
            }
        }

        updateVariableOptions(variableOptionTrees);
    }

    function setMenu(newMenu) {
        props.setSubMenu(newMenu);
    }

    function updateVariableLists(selectedHeading) {

        const currentOpenHeadings = [...variableTreeOpenLists];
        const headingIndex = currentOpenHeadings.findIndex((heading) => heading == selectedHeading.id);

        if (headingIndex == -1) {
            currentOpenHeadings.push(selectedHeading.id);
        } else {
            currentOpenHeadings.splice(headingIndex, 1);
        }
        props.setVariableCollapsible(currentOpenHeadings);
    }

    useEffect(() => {
        props.setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
        constructVariableTree();
        fetchCurrentFilterOptions();
    }, [])


    const DropdownItem = (props) => {
        return (
            <Typography variant='overline' className="custom-menu-item" onClick={() => props.nextMenu && setMenu(props.nextMenu)}>
                <span className="dropdown-left-icon">{props.leftIcon}</span>
                    {props.children}
                <span className="dropdown-right-icon">{props.rightIcon}</span>
            </Typography>
        );
    }

    const ResetButton = () => {
        const allCriteria = props.getCurrentFilters();
        const timeFrameSelected = allCriteria[2].filter(timeframe => timeframe != null);
        if ((timeFrameSelected.length > 0 || allCriteria[0].length > 0 || allCriteria[1].length > 0 || allCriteria[3].length > 0)) {
            return (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Button style={{ width: 'fit-content'}} onClick={() => resetFilters()}>Reset Filters</Button>
                </div>
            )
        } else if (timeFrameSelected.length == 0 && allCriteria[0].length == 0 && allCriteria[1].length == 0 && allCriteria[3].length == 0) {
            return null;
        }
    }

    const DateFilterButton = () => {
        const nonNullDates = selectedTimeFrame.filter(timeframe => timeframe != null);
        let allDatesValid = nonNullDates.length > 0 ? nonNullDates.filter(timeframe => timeframe.isValid() && timeframe.year() >= 1900).length > 0 ? true : false : false;

        if (nonNullDates.length > 1) {
            allDatesValid = selectedTimeFrame[0] < selectedTimeFrame[1] ? true : false;
        }

        return (
            <Button disabled={allDatesValid ? false : true} onClick={() => props.filterBy([selectedOrganizations, selectedVariables, selectedTimeFrame]) } style={{ marginTop: "5px"}}>Search Date</Button>
        )
    }

    const VariableTreeDropdown = (props) => {
        const parentOpen = variableTreeOpenLists.includes(props.parent.id);
        const variables = props.parent.children;

        if (parentOpen) {
            return (
                <div key={props.parent.id} style={{ height: '150px', width: '100%', overflowY: 'auto', overflowX: 'hidden'}}>
                    {variables.map((variable, index) => {
                        return(
                            <div key={index}>
                                <FormControlLabel
                                    className="custom-variable-item"
                                    sx={dropdown_checklist}
                                    label={variable.name}
                                    control={<Checkbox checked={variableIsSelected(variable.id)} onChange={() => updateFilteredVariables(variable)}  />}
                                />
                            </div>
                        )
                    })}
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <div className="custom-dropdown" style={{ height: props.menuHeight + 30 }} ref={dropdownRef}>
            <CSSTransition in={props.subMenu === 'main'} unmountOnExit timeout={500} classNames="custom-menu-primary" onEnter={calcHeight}>
                <div className='custom-menu'>
                    <Typography variant='overline' className="custom-menu-item" onClick={() => props.toggleDrawing()}>
                        <span className="dropdown-left-icon"><FaExpand size={20} /></span>
                        Location
                    </Typography>
                    <DropdownItem leftIcon={<FaRegBuilding size={20} />} rightIcon={<FaChevronRight />} nextMenu="org">Organization</DropdownItem>
                    <DropdownItem leftIcon={<FaList size={20} />} rightIcon={<FaChevronRight />} nextMenu="variables">Variables</DropdownItem>
                    <DropdownItem leftIcon={<FaRegCalendarAlt size={20} />} rightIcon={<FaChevronRight />} nextMenu="time">Time</DropdownItem>
                    <ResetButton />
                    <Button style={{width: 'fit-content'}} onClick={() => props.toggleBottomTable()}>View Filter Table</Button>
                </div>

            </CSSTransition>

            <CSSTransition in={props.subMenu === 'org'} unmountOnExit timeout={500} classNames="custom-menu-org" onEnter={calcHeight}>
                <div className='custom-menu'>
                    <DropdownItem leftIcon={<FaChevronLeft />} nextMenu="main" />
                    {organizationOptions.map((org) => {
                        return(
                            <FormControlLabel
                                key={org.title}
                                className="custom-menu-item"
                                sx={dropdown_checklist}
                                label={org.title}
                                control={<Checkbox checked={organizationIsSelected(org)} onChange={() => updateFilteredOrganizations(org)}  />}
                            />
                        )
                    })}
                </div>
            </CSSTransition>

            <CSSTransition in={props.subMenu === 'variables'} unmountOnExit timeout={500} classNames="custom-menu-variables" onEnter={calcHeight}>
                <div className='custom-menu'>
                    <DropdownItem leftIcon={<FaChevronLeft />} nextMenu="main" />
                    <div style={{ height: '250px', width: '100%', marginLeft: '10px', overflowY: 'auto', overflowX: 'hidden'}}>
                        {variableTreeOptions.map((tree, index) => {
                            return (
                                <div key={index}>
                                    <Button style={{ width: "95%", color: "#000"}} onClick={() => updateVariableLists(tree)}>
                                        {tree.name}
                                        <span className="dropdown-right-icon">{variableTreeOpenLists.includes(tree.id) ? <FaChevronUp /> : <FaChevronDown />}</span>
                                    </Button>
                                    <VariableTreeDropdown parent={tree} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </CSSTransition>

            <CSSTransition in={props.subMenu === 'time'} unmountOnExit timeout={500} classNames="custom-menu-time" onEnter={calcHeight}>
                <div className='custom-time-menu'>
                    <DropdownItem leftIcon={<FaChevronLeft />} nextMenu="main" />

                    <div className='date-pick-container'>
                        <Typography variant='overline'>Start Date</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker label="Start date" value={getStartDate()} onChange={(newStartDate) => setStartDate(newStartDate)} onOpen={() => setDateDialogStatus(true)} onClose={() => setDateDialogStatus(false)} />
                        </LocalizationProvider>
                    </div>

                    <div className='date-pick-container'>
                        <Typography variant='overline'>End Date</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker label="End date" value={getEndDate()} onChange={(newEndDate) => setEndDate(newEndDate)} onOpen={() => setDateDialogStatus(true)} onClose={() => setDateDialogStatus(false)} />
                        </LocalizationProvider>
                    </div>

                    <DateFilterButton />
                </div>
            </CSSTransition>
        </div>
    )

}

export default DiscoverDropdown;
