import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';


import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



import { getErddapMetadata } from '../../utils/apiRequestBuilder';


const detailedRiskModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
};

const details_header = {
    backgroundColor: "rgb(29, 162, 216)",
    height: '40px',
    padding: '15px 10px',
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: 'center',
};

const metadataDiv = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'auto',
    height: '55vh'
};

const metadataTitle = {
    display: 'block',
    padding: '3px 0'
}

const metadataValue = {
    display: 'block'
}

const listStationsContainerStyle = {
    maxHeight: 'calc(70vh - 40px - 48px)',  // 40px for header, 48px for tabs
    overflowY: 'auto',
    padding: '0px',
};


const stationItemStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid gray',
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '10px'
};

const StationDetails = (props) => {
    const [currentTab, updateCurrentTab] = React.useState("1");
    const [loadingDatasets, updateDatasetLoading] = React.useState(true);
    const [datasetsMetadata, updateErddapMetadata] = React.useState([]);

    //For DatasetsTab
    const [isEditing, setIsEditing] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [datasetToDelete, setDatasetToDelete] = useState(null);

    const station = props.station;

    useEffect(() => {
        loadDatasetsMetadata();
    }, []);

    async function loadDatasetsMetadata() {
        const erddapDatasets = station.resources;

        for (let i = 0; i < erddapDatasets.length; i++) {
            const urlSplitArr = erddapDatasets[i].url.split("/");

            const baseUrl = urlSplitArr[2];
            const erddapDatasetName = urlSplitArr.slice(-1)[0].split('.')[0]; // this may be temporary since ckan has an incorrect url

            erddapDatasets[i].displayMetadata = {} // to hold metadata we want to dsisplay

            const [request, options] = await getErddapMetadata(baseUrl, erddapDatasetName);
            const response = await fetch(request, options);
            if (response.status === 200) {
                const json = await response.json();
                const columnNames = json.table.columnNames;
                const rows = json.table.rows;

                const rowTypeIndex = columnNames.findIndex((column) => column == 'Row Type');
                const variableNameIndex = columnNames.findIndex((column) => column == 'Variable Name');
                const attributeNameIndex = columnNames.findIndex((column) => column == 'Attribute Name');
                const valueIndex = columnNames.findIndex((column) => column == 'Value');
                const variablesToExclude = ['time', 'longitude', 'latitude', 'stationid'];
                let time_coverage_start = '';
                let time_coverage_end = '';
                let station_id = '';
                let dataset_title = '';
                let dataset_source = '';
                const variables = [];
                const variable_names = [];
                rows.map((row) => {
                    if (row[rowTypeIndex] == 'variable' && !variablesToExclude.includes(row[variableNameIndex])) {
                        variables.push(row[variableNameIndex]);
                    }

                    if (variables.includes(row[variableNameIndex]) && !variablesToExclude.includes(row[valueIndex]) && (row[attributeNameIndex] == "standard_name" || row[attributeNameIndex] == "short_name")) {
                        if (!variable_names.includes(row[valueIndex].replaceAll('_', ' '))) {
                            variable_names.push(row[valueIndex].replaceAll('_', ' '));
                        }
                    }

                    if (row[attributeNameIndex] == 'time_coverage_start') { time_coverage_start = row[valueIndex]; }
                    if (row[attributeNameIndex] == 'time_coverage_end') { time_coverage_end = row[valueIndex]; }
                    if (row[attributeNameIndex] == 'stationid') { station_id = row[valueIndex]; }
                    if (row[attributeNameIndex] == 'title') { dataset_title = row[valueIndex]; }
                    if (row[attributeNameIndex] == 'source') { dataset_source = row[valueIndex]; }
                });

                erddapDatasets[i].displayMetadata.title = dataset_title;
                erddapDatasets[i].displayMetadata.source = dataset_source;
                erddapDatasets[i].displayMetadata.station_id = station_id;
                erddapDatasets[i].displayMetadata.time_coverage_start = time_coverage_start;
                erddapDatasets[i].displayMetadata.time_coverage_end = time_coverage_start;
                erddapDatasets[i].displayMetadata.erddap_metadata_url = erddapDatasets[i].url.replace('tabledap', 'info').replace('.html', '/index.html');
                erddapDatasets[i].displayMetadata.erddap_data_url = erddapDatasets[i].url;
                erddapDatasets[i].displayMetadata.variables = variable_names;
            }
        }

        updateErddapMetadata(erddapDatasets);
        updateDatasetLoading(false);
    }

    function handleTabChange(newTabEvent, newValue) {
        updateCurrentTab(newValue);
    }

    function camelCaseToSentenceCase(s) {
        if (s == null) {
            return;
        }
        const finalResult = s.charAt(0).toUpperCase() + s.slice(1);
        return finalResult;
    }

    function formatMetadataKey(value) {
        if (typeof value === 'string') {
            const camelCase = camelCaseToSentenceCase(value);
            const formatedValue = camelCase.replaceAll('_', ' ');
            return formatedValue;
        } else {
            return value;
        }
    }

    function formatMetadataValue(metadata_key) {
        const value = station[metadata_key];

        if (metadata_key.includes('metadata')) {
            return formatDate(value);
        } else if (metadata_key.includes('ckan')) {
            return (<a href={value} target='_blank'>Metadata (CKAN)</a>);
        } else if (metadata_key == 'coordinates') {
            return value.join(', ');
        } else if (metadata_key.includes('keywords')) {
            const keywordsArr = value.split(',');
            return keywordsArr.map((keyword) => { return (<span key={keyword} style={{ padding: '5px', display: 'inline-block', backgroundColor: 'rgba(29, 162, 216, 0.2)', borderRadius: '10px', marginRight: '5px', marginBottom: '10px' }}> {formatMetadataKey(keyword)} </span>) });
        } else {
            return value;
        }
    }

    const formatDatasetMetadata = (key, value, isEditing) => {
        // Ensure value is an array before trying to map or join
        if (key.includes('variables') && Array.isArray(value)) {
            if (isEditing) {
                return value.join(', ');
            } else {
                return value.map((variable) => {
                    return (
                        <span key={variable} style={{ padding: '5px', display: 'inline-block', backgroundColor: 'rgba(29, 162, 216, 0.2)', borderRadius: '10px', marginRight: '5px', marginBottom: '10px' }}>
                            {formatMetadataKey(variable)}
                        </span>
                    );
                });
            }
        } else if (key.includes('time')) {
            return formatDate(value);
        } else if (key.includes('url')) {
            return <a href={value} target='_blank'> {key.includes('metadata') ? 'Metadata (Erddap)' : 'Data (Erddap)'}</a>;
        } else {
            return value;
        }
    };

    function formatDate(date) {
        const milliseconds = Date.parse(date);
        const formatedDate = new Date(milliseconds).toString();
        return formatedDate;
    }

    const handleEditButtonClick = () => {
        setIsEditing(!isEditing);
    };

    const updateMetadataValue = (metadataId, key, value) => {
        const metadataToUpdate = datasetsMetadata.find((metadata) => metadata.id === metadataId);
        if (metadataToUpdate) {
            // Transform value back into an array if it's the 'variables' key
            if (key.includes('variables')) {
                value = value.split(',').map(item => item.trim());
            }
            metadataToUpdate.displayMetadata[key] = value;
            updateErddapMetadata([...datasetsMetadata]);
        }
    };

    const handleDeleteDataset = (datasetId) => {
        setDatasetToDelete(datasetId);
        setDeleteDialogOpen(true);
    };
    const handleConfirmDelete = () => {
        const updatedDatasetsMetadata = datasetsMetadata.filter(dataset => dataset.id !== datasetToDelete);
        updateErddapMetadata(updatedDatasetsMetadata);
        setDeleteDialogOpen(false);
        setDatasetToDelete(null);
    };
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDatasetToDelete(null);
    };

    const renderDatasetInfoTab = () => {
        if (loadingDatasets) {
            return (
                <div style={{ margin: 'auto', textAlign: 'center', position: 'absolute', top: '40%', left: '40%' }}>
                    <CircularProgress />
                    <Typography variant="body1">Fetching Metadata</Typography>
                </div>
            )
        } else {
            return (
                <div>
                    <Button variant="outlined" size="large" onClick={handleEditButtonClick} style={{ float: 'right' }}>{isEditing ? 'Save All' : 'Edit All'}</Button>
                    {datasetsMetadata.map((metadata) => {
                        return (
                            <div key={metadata.id} style={{ marginBottom: '15px' }}>
                                <Typography variant="overline" style={{ 'fontWeight': 'bold' }}>Title: {metadata.displayMetadata.title}</Typography>
                                <div style={{ marginTop: '15px', marginLeft: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {Object.keys(metadata.displayMetadata).map((displayKey) => {
                                        const isUrlField = displayKey.includes('url');
                                        return (
                                            <div key={displayKey} style={{ width: '25%', padding: '5px', marginTop: '5px' }}>
                                                <span style={metadataTitle}><Typography variant='subtitle2'>{formatMetadataKey(displayKey)}</Typography></span>
                                                <span style={metadataValue}><Typography style={{ fontWeight: 'bold' }} variant='caption'>
                                                    {isEditing
                                                        ? <TextField value={metadata.displayMetadata[displayKey]} onChange={e => updateMetadataValue(metadata.id, displayKey, e.target.value)} />
                                                        : isUrlField
                                                            ? <a href={metadata.displayMetadata[displayKey]} target='_blank'> {displayKey.includes('metadata') ? 'Metadata (Erddap)' : 'Data (Erddap)'}</a>
                                                            : formatDatasetMetadata(displayKey, metadata.displayMetadata[displayKey])
                                                    }
                                                </Typography></span>
                                            </div>
                                        )
                                    })}
                                    <Button variant="outlined" color="secondary" size="small" onClick={() => handleDeleteDataset(metadata.id)} style={{ marginTop: '5px' }}>Delete</Button>
                                </div>
                                <Dialog
                                    open={deleteDialogOpen}
                                    onClose={handleCloseDeleteDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this dataset?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
                                            Delete
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <div style={{ height: '0.5px', background: 'black' }}></div>
                            </div>
                        )
                    })}
                </div>
            )
        }
    };

    const StationInfoTab = () => {
        const metadataToDisplay = ['name', 'title', 'coordinates', 'station_type', 'number_of_datasets', 'organization_name', 'notes', 'metadata_created', 'metadata_modified', 'state', 'ckan'];

        return metadataToDisplay.map((metadataKey) => {
            return (
                <div key={metadataKey} style={{ width: '50%', padding: '5px', marginTop: '5px' }}>
                    <span style={metadataTitle}><Typography variant='overline'>{formatMetadataKey(metadataKey)}</Typography></span>
                    <span style={metadataValue}><Typography style={{ fontWeight: 'bold' }} variant='body2'>{formatMetadataValue(metadataKey)}</Typography></span>
                </div>
            )
        });
    }
    const handleClose = () => {
        props.onClose();
    }

    return (
        <div style={detailedRiskModalStyle}>
            <Paper sx={{ width: '70vw', height: '70vh' }}>
                <div style={details_header}>
                    <Typography variant='h6' style={{ color: "#fff" }}>{props.station.title}</Typography>
                    <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>

                <TabContext value={currentTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="detailstab">
                            <Tab label="Datasets" value="1" />
                            <Tab label="Station" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" style={{ display: 'flex', flexDirection: 'column', overflow: 'auto', height: '60vh' }}>{renderDatasetInfoTab()}</TabPanel>
                    <TabPanel value="2"><div style={metadataDiv}> <StationInfoTab /> </div></TabPanel>
                </TabContext>
            </Paper>
        </div>
    );

};

const StationItem = ({ stat, onViewDetails, onDelete }) => {
    return (
        <div style={stationItemStyle}>
            <Typography variant="body1">{stat.title}</Typography>
            <div>
                <Button onClick={(e) => { e.stopPropagation(); onViewDetails(stat); }}>View Details</Button>
                <Button onClick={(e) => { e.stopPropagation(); onDelete(stat.id); }}>Delete</Button>
            </div>
        </div>
    );
};


const ManageModal = ({ allStations: initialStations }) => {

    const[currentStation, setCurrentStation] = useState(null);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [allStations, setAllStations] = useState(initialStations);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [stationToDelete, setStationToDelete] = useState(null);

    const [currentTab, setCurrentTab] = useState('1');

    const handleManageTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };


    const handleDeleteButtonClick = (id) => {
        setStationToDelete(id);
        setDeleteDialogOpen(true);
    }

    const confirmDeleteStation = () => {
        setAllStations(allStations.filter(station => station.id !== stationToDelete));
        setDeleteDialogOpen(false);
        setStationToDelete(null);
    }

    const cancelDeleteStation = () => {
        setDeleteDialogOpen(false);
        setStationToDelete(null);
    }

    const ListStations = () => {
        return (
            <div style={listStationsContainerStyle}>
                {allStations.map((station) => {
                    return <StationItem
                        stat={station}
                        key={station.id}
                        onViewDetails={(stat) => {
                            setCurrentStation(stat);
                            setIsDetailsModalOpen(true);
                        }}
                        onDelete={handleDeleteButtonClick}
                    />
                })}
            </div>
        )
    }

    const closeDetailsModal = () => {
        setIsDetailsModalOpen(false);
        setCurrentStation(null);
    }

    const data = [
        {
            datasetTitle: "DWR4 WAVE DATA",
            submittedBy: "terry.bullock@wsp.com",
            dateSubmitted: "2023-04-18",
            approval1: "",
            approval2: "",
            status: "Pending approval",
            yourTasks: "Review",
            type: "approved",
        },
        {
            datasetTitle: "DWR4 SST DATA",
            submittedBy: "terry.bullock@wsp.com",
            dateSubmitted: "2023-04-18",
            approval1: "",
            approval2: "",
            status: "Pending approval",
            yourTasks: "Review",
            type: "approved",
        },
        {
            datasetTitle: "DWR4 CURRENT DATA",
            submittedBy: "terry.bullock@wsp.com",
            dateSubmitted: "2023-04-18",
            approval1: "",
            approval2: "",
            status: "Pending approval",
            yourTasks: "Review",
            type: "under review",
        },
        {
            datasetTitle: "Some Dataset that is in progress?",
            submittedBy: "terry.bullock@wsp.com",
            dateSubmitted: "2023-04-18",
            approval1: "",
            approval2: "",
            status: "Pending approval",
            yourTasks: "Review",
            type: "under review",
        }
    ];

    const getStatusChip = (type) => {
        let color;
        switch (type) {
            //archived data
            case 'approved':
                color = 'green';
                break;
            case 'under review':
                color = 'grey';
                break;
            default:
                color = 'default';
        }

        return (
            <Chip
                size="small"
                style={{
                    backgroundColor: color,
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%',
                }}
            />
        );
    };


    const pendingApprovals = [
        { label: 'Approved', color: 'green' },
        { label: 'Under Review', color: 'grey' },
    ];

    const realTimeStatusLegend = [
        { label: 'Real-time', color: 'green' },
        { label: 'Delayed', color: 'orange' },
        { label: 'Down', color: 'red' },
        { label: 'Under Review', color: 'purple' },
    ];

    const archivedStatusLegend = [
        { label: 'Approved', color: 'blue' },
        { label: 'Delayed', color: 'orange' },
    ];

    // Define the legend styles
    const legendStyles = {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        margin: 0,
        padding: 0,
        paddingLeft: '16px', 
    };

    const legendItemStyles = {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px',
    };



    const PendingApprovalsTab = () => {

        return (
            <div>
                <div style={legendStyles}>
                    {pendingApprovals.map((item, index) => (
                        <div key={index} style={legendItemStyles}>
                            <Chip
                                size="small"
                                style={{
                                    backgroundColor: item.color,
                                    width: '16px',
                                    height: '16px',
                                    borderRadius: '50%',
                                }}
                            />
                            <span style={{ marginLeft: '4px' }}>{item.label}</span>
                        </div>
                    ))}
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Dataset Title</TableCell>
                                <TableCell align="center">Submitted By</TableCell>
                                <TableCell align="center">Date Submitted</TableCell>
                                <TableCell>Approval 1</TableCell>
                                <TableCell>Approval 2</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Your Tasks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.datasetTitle}</TableCell>
                                    <TableCell align="center">{row.submittedBy}</TableCell>
                                    <TableCell align="center">{row.dateSubmitted}</TableCell>
                                    <TableCell>{row.approval1}</TableCell>
                                    <TableCell>{row.approval2}</TableCell>
                                    <TableCell align="center">{getStatusChip(row.type)}</TableCell>
                                    <TableCell align="center">{row.yourTasks}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };



    const renderDataHealthDashboardTab = () => {
        const getStatusChip = (type) => {
            let color;
            switch (type) {
                case 'real-time':
                    color = 'green';
                    break;
                case 'delayed':
                    color = 'orange';
                    break;
                case 'down':
                    color = 'red';
                    break;
                case 'review':
                    color = 'purple';
                    break;
                case 'progress':
                    color = 'grey';
                    break;
                default:
                    color = 'default';
            }

            return (
                <Chip
                    size="small"
                    style={{
                        backgroundColor: color,
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        margin: 'auto',
                    }}
                />
            );
        };

        const data = [
            { datasetTitle: "DWR4 WAVE DATA", type: "real-time", lastRecord: "2023-05-30" },
            { datasetTitle: "DWR4 SST DATA", type: "delayed", lastRecord: "2023-05-29" },
            { datasetTitle: "DWR4 CURRENT DATA", type: "down", lastRecord: "2023-05-28" },
            { datasetTitle: "HIBERNIA MANMAR DATA", type: "review", lastRecord: "2023-05-27" },
        ]

        return (
            <div>
                <div style={legendStyles}>
                    {realTimeStatusLegend.map((item, index) => (
                        <div key={index} style={legendItemStyles}>
                            <Chip
                                size="small"
                                style={{
                                    backgroundColor: item.color,
                                    width: '16px',
                                    height: '16px',
                                    borderRadius: '50%',
                                }}
                            />
                            <span style={{ marginLeft: '4px' }}>{item.label}</span>
                        </div>
                    ))}
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Dataset Title</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Last Record</TableCell>
                                <TableCell align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.datasetTitle}</TableCell>
                                    <TableCell align="center">{row.type}</TableCell>
                                    <TableCell align="center">{row.lastRecord}</TableCell>
                                    <TableCell align="center">{getStatusChip(row.type)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };



    const renderArchiveDataHealthDashboardTab = () => {
        const getStatusChip = (type) => {
            let color;
            switch (type) {
                case 'approved':
                    color = 'blue';
                    break;
                case 'delayed':
                    color = 'orange';
                    break;
                default:
                    color = 'default';
            }

            return (
                <Chip
                    size="small"
                    style={{
                        backgroundColor: color,
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        margin: 'auto',
                    }}
                />
            );
        };

        const data = [
            { datasetTitle: "DWR4 WAVE DATA", type: "approved", lastRecord: "2023-05-30" },
            { datasetTitle: "DWR4 SST DATA", type: "delayed", lastRecord: "2023-05-29" },
        ]

        return (
            <div>
                <div style={legendStyles}>
                    {archivedStatusLegend.map((item, index) => (
                        <div key={index} style={legendItemStyles}>
                            <Chip
                                size="small"
                                style={{
                                    backgroundColor: item.color,
                                    width: '16px',
                                    height: '16px',
                                    borderRadius: '50%',
                                }}
                            />
                            <span style={{ marginLeft: '4px' }}>{item.label}</span>
                        </div>
                    ))}
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Dataset Title</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Last Record</TableCell>
                                <TableCell align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.datasetTitle}</TableCell>
                                    <TableCell align="center">{row.type}</TableCell>
                                    <TableCell align="center">{row.lastRecord}</TableCell>
                                    <TableCell align="center">{getStatusChip(row.type)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };

    return (
        <div style={detailedRiskModalStyle}>
            <Paper sx={{ width: '70vw', height: '70vh', overflow: 'hidden' }}>
                <div style={details_header}>
                    <Typography variant='h6' style={{ color: "#fff" }}>Manage</Typography>
                </div>
                {isDetailsModalOpen ?
                    <StationDetails
                        station={currentStation}
                        onClose={closeDetailsModal}
                    /> :
                    <>
                        <Tabs
                            value={currentTab}
                            onChange={handleManageTabChange}
                            aria-label="manage tabs"
                        >
                            <Tab label="List Stations" value="1" />
                            <Tab label="Pending Approvals" value="2" />
                            <Tab label="Real-Time Data Health Dashboard" value="3" />
                            <Tab label="Archived Data Health Dashboard" value="4" />
                        </Tabs>
                        {currentTab === '1' && <ListStations />}
                        {currentTab === '2' && <PendingApprovalsTab />}
                        {currentTab === '3' && renderDataHealthDashboardTab()}
                        {currentTab === '4' && renderArchiveDataHealthDashboardTab()}
                    </>
                }
                <Dialog
                    open={deleteDialogOpen}
                    onClose={cancelDeleteStation}
                >
                    <DialogTitle>Delete Station</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this station? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancelDeleteStation} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={confirmDeleteStation} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </div>
    )
}

export default ManageModal;
