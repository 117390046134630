import React, { useRef, useEffect } from 'react';
import { MapComponent } from '../components/Map';
import { MapNav, MapOuterDiv, MapNavbarContainer, MapNavLogo } from '../components/Map/MapElements';
import { Navigate, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import L from "leaflet";
import { getAllDatasetsResources, getErddapDatasetsWithinTimeRange } from '../utils/apiRequestBuilder';
import { SettingsDropdown } from '../components/Settings/settingsDropdown';
import { DataGrid, GridToolbarFilterButton, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import DetailsModal from '../components/DetailsModal';
import ManageModal from '../components/ManageModal';
import moment from 'moment';
import DiscoverDropdown from '../components/DiscoverDropdown';
import DataUploadModal from '../components/DataUploadModal';
import { useSpring, animated } from "react-spring";


const loaderStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    bgcolor: 'rgba(0,0,0,0.3)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px 20px 30px'
};

const loadingDescriptionStyle = {
    fontSize: '1.2rem',
    color: 'white',
    marginTop: '10px'
};

const nav_button = {
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold"
}

const mapAnalysisToggleButton = {
    color: '#fff',
    bgcolor: '#000',
    '&:hover': {
      bgcolor: 'rgba(33,33,33,1)',
    }
}

const bottomPanelStyle = {
    height: 350,
    width:'99.5vw',
    opacity: 0.97
}

const data_grid_styles ={
    opacity:0.9,
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {width: 10, height: 10},
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {background: '#f1f1f1',},
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {backgroundColor: '#888',},
    '& .MuiTablePagination-displayedRows':{marginBottom:0},
    "& .MuiDataGrid-columnHeaders":{backgroundColor:'#f6fafd', fontWeight:'bold'},
    "& .MuiDataGrid-columnHeaderTitle":{fontWeight:'bold', overflow: "visible"},
    "& .MuiDataGrid-columnHeaderTitleContainer":{justifyContent:'center'},
    "& .MuiDataGrid-cell":{justifyContent:'center'}
}




const MapPage = () => {

    const navigate = useNavigate();
    const mapCompRef = useRef();

    const [isLoading, updateLoading] = React.useState(true);
    const [loadingDescription, updateLoadingDescription] = React.useState('Loading..');
    const [datasetResources, updateDatasetResources] = React.useState([]);
    const [showBottomTab, toggleBottomPanel] = React.useState(false);
    const [currentTabDatasets, updateTabDatasets] = React.useState([]);

    const [detailedModalOpen, toggleDetailedModal] = React.useState(false);
    const [manageModalOpen, toggleManageModal] = React.useState(false);
    const [dataUploadModalOpen, toggleUploadModal] = React.useState(false);
    const [currentDetailDataset, updateCurrentDetails] = React.useState(null);

    const [allErddapSources, updateErddapSources] = React.useState([]);
    const [allDatasetVariables, updateAllVariables] = React.useState([]);
    const [filteredVariables, updateFilteredVariables] = React.useState([]);
    const [allDatasetOrganizations, updateAllOrganizations] = React.useState([]);
    const [filteredOrganizations, updateFilteredOrganizations] = React.useState([]);

    const [filteredTimeframe, updateFilteredTimeframe] = React.useState([null,null]);
    const [filteredLocation, updateFilteredLocation] = React.useState([]);


    // discover dropdown menu states to prevent unnecessary reloading to default values
    const [discoverDropdownActive, updateDiscoverDropdownStatus] = React.useState(false);
    const [discoverDropdownSubMenu, setDiscoverDropdownSubMenu] = React.useState("main"); // we need this here to prevent rendering of dropdown to main menu after filter selection
    const [discoverDropdownHeight, setDiscoverDropdownHeight] = React.useState(null);
    const [discoverDropdownVariableMenu, setDiscoverDropdownVariableSubMenu] = React.useState([]); // used to track collaspable lists in variable list to prevent collaspable status from resetting to default after variable is selected (hacky)


    const resourcesRef = useRef();
    resourcesRef.current = datasetResources;

    const closeBottomPanel = () => toggleBottomPanel(false);
    const openBottomPanel = () => toggleBottomPanel(true);

    useEffect(() => {
        loadInitialData();
    }, []);

    function setDiscoverDropdownStatus(status) {
        updateDiscoverDropdownStatus(status);
        setDiscoverDropdownSubMenu("main");
        setDiscoverDropdownVariableSubMenu([]);
    }

    function openDetailModal(currentStation) {
        updateCurrentDetails(currentStation);
        toggleDetailedModal(true);
    }

    function closeDetailModal() {
        updateCurrentDetails(null);
        toggleDetailedModal(false);
    }

    function openManageModal() {
        toggleManageModal(true);
    }

    function closeManageModal() {
        toggleManageModal(false);
    }

    function toggleDataUploadModal() {
        toggleUploadModal(!dataUploadModalOpen);
    }

    function closeDataUploadModal() {
        toggleUploadModal(false);
    }

    function toggleBPanel() {
        toggleBottomPanel(!showBottomTab);
    }

    async function loadInitialData() {
        try {
            updateLoadingDescription('Loading Datasets..');
            await loadAllDatasets();
            hideDrawingToolControls();
            updateLoading(false);
            updateLoadingDescription('Loading..');
        } catch (e) {
            console.log(e);
        }
    }

    function camelCaseToSentenceCase(s) {
        if (s == null) {
          return;
        }
        const finalResult = typeof s === 'string' ? s.charAt(0).toUpperCase() + s.slice(1) : s;
        return finalResult;
    }

    async function loadAllDatasets() {

        const [request, options] = await getAllDatasetsResources();
        const response = await fetch(request, options);
        if (response.status === 200) {
          const json = await response.json();
          const resources = json.result;

          const organizations = [];
          let variables = []; // unique variables
          const erddapSources = [];

          // looping through each ckan dataset to collect data
          for (let i = 0; i < resources.length; i++) {

            const organization = resources[i].organization;
            const orgNotAdded = organizations.findIndex((org) => org.id == organization.id) == -1 ? true : false;
            if (orgNotAdded) { // we only want unique organizations
                organizations.push(organization);
            }

            // getting erddap sources
            const station_erddap_sources = resources[i].resources.map((r) => {
                return r.url.split('/')[2];
            });

            station_erddap_sources.forEach((source_url) => {
                if (!erddapSources.includes(source_url)) {
                    erddapSources.push(source_url);
                }
            });

            const stationTypeInfo = resources[i].extras.find((extra) => extra.key == 'station_type');
            const keywordInfo = resources[i].extras.find((extra) => extra.key == 'keywords');

            const spatialInfo = resources[i].extras.find((extra) => extra.key == 'spatial');
            const coordinates = JSON.parse(spatialInfo.value).coordinates;
            const formatedCoordinates = coordinates.map((num) => { return num.toFixed(2); });

            const variableMetadata = resources[i].extras.find((extra) => extra.key == 'variables');
            const datasetVariables = JSON.parse(variableMetadata.value);
            const variableDifference = datasetVariables.filter(x => !variables.includes(x)); // getting variables that are not already in global variable set
            variables = variables.concat(variableDifference);

            const temporalMetadata = resources[i].extras.find((extra) => extra.key == 'temporal_extent');
            const temporalExtent = JSON.parse(temporalMetadata.value);


            // setting some nested attributes as higher level sttributes
            resources[i].coordinates = formatedCoordinates;
            resources[i].station_type = stationTypeInfo.value;
            resources[i].organization_name = organization.title;
            resources[i].keywords = keywordInfo.value;
            resources[i].variables = datasetVariables;
            resources[i].temporal_extent = temporalExtent;
            resources[i].number_of_datasets = resources[i].resources.length;
            resources[i].ckan = 'https://ckan.odf.decisionvue.net/dataset/' + resources[i].name;

          }

          // As of now for the demo, we would like to show an option of Organization DFO MEDS but since we have no datasets in ckan i manually insert it here
          if (organizations.findIndex((org) => org.id == 'dfo_meds') == -1) {
              organizations.push({id: 'dfo_meds', name: 'dfo_meds', title: 'DFO MEDS'});
          }

          updateErddapSources(erddapSources);
          updateAllOrganizations(organizations);
          updateAllVariables(variables);
          updateDatasetResources(resources);
          addStationsToMap(resources);

        } else {
            //handle error here
            console.log(response.status);
        }
    }

    async function addStationsToMap(stations) {

        const geoJSONData = createGeoJSONFormat(stations);

        const defaultMarkerOptions = { radius: 6, fillColor: "rgba(168, 208, 141, 1)", color: "#000", weight: 2, opacity: 1, fillOpacity: 0.8 };

        // remove old stations if already present
        const layers = mapCompRef.current.mapRef()._layers;
        const mapLayerkeys = Object.keys(layers);
        const stationLayerIndex = mapLayerkeys.findIndex(layerKey => layers[layerKey].options.id == "stations");
        if (stationLayerIndex != -1) {
            const layerKeyToRemnove = mapLayerkeys[stationLayerIndex];
            mapCompRef.current.mapRef().removeLayer(layers[layerKeyToRemnove]);
        }

        const geoJSON = L.geoJSON(geoJSONData, { // new JSON layer
            id: 'stations',
            pointToLayer: (geoJsonPoint, latlng) => {
                return L.circleMarker(latlng, defaultMarkerOptions);
            },
            onEachFeature: function popUp(f, l) {
                displayStationTooltip(l, f.properties, f.full_detail);
            }
        });

        mapCompRef.current.mapRef().addLayer(geoJSON);

    }

    function createGeoJSONFormat(stations) {
        const stationsGeoJson = [];
        for(let i=0; i < stations.length; i++) {
            const stationFeature = {"type": "Feature", "properties": {}, "geometry": { "type": "Point", "coordinates": [0,0] }};
            stationFeature['geometry']['coordinates'] = stations[i].coordinates;
            stationFeature['properties']['title'] = stations[i].title;
            stationFeature['properties']['organization'] = stations[i].organization.title;
            stationFeature['properties']['type'] = stations[i].station_type;
            stationFeature['properties']['datasets'] = stations[i].number_of_datasets;
            stationFeature['properties']['state'] = stations[i].state;
            stationFeature['full_detail'] = stations[i];
            stationsGeoJson.push(stationFeature);
        }

        return stationsGeoJson;
    }

    async function displayStationTooltip(mapRef, metadata, all_info) {

        const toolTipContent = [];
        for (const key in metadata) {
            const camelCaseKey = camelCaseToSentenceCase(key);
            const formatedValue = typeof metadata[key] === 'string' ? camelCaseToSentenceCase(metadata[key]) : metadata[key];
            toolTipContent.push('<span style="margin: 2px; font-size: 14px;"> <b>' + camelCaseKey + "</b>:" + formatedValue + "</span>");
        }

        mapRef.bindTooltip(toolTipContent.join("<br />"));
        mapRef.on('click', function(e) {
            openDetailModal(all_info);
        });
    }

    function fetchCurrentMenuSelections() {
        return [filteredOrganizations, filteredVariables, filteredTimeframe, filteredLocation];
    }

    function updateFilterCriteria(criteria) {
        updateFilteredOrganizations(criteria[0]);
        updateFilteredVariables(criteria[1]);
        updateFilteredTimeframe(criteria[2]);
    }

    async function filterStationsByTime(stations, timeframe) {

        const stationsWithinTime = [];
        for (const sourceIndex in allErddapSources) {

            const source = allErddapSources[sourceIndex];
            const timeRange = timeframe.map((time) => time != null ? time.format("YYYY-MM-DD") : null);
            const [request, options] = await getErddapDatasetsWithinTimeRange(source, timeRange[0], timeRange[1]);
            const response = await fetch(request, options);
            if (response.status === 200) {
                const json = await response.json();

                const columnNames = json.table.columnNames;
                const rows = json.table.rows;
                const datasetIDIndex = columnNames.findIndex((column) => column == 'Dataset ID');
                const datasetsWithinTimeframe = [];
                rows.forEach((row) => { datasetsWithinTimeframe.push(row[datasetIDIndex]) });
                stations.forEach((station) => { 
                    // checking if each station includes a dataset/resource that falls within time range
                    const stationIncludesDataset = station.resources.map((resource) => {
                        if (datasetsWithinTimeframe.includes(resource.name)) { return true; }
                    });

                    if (stationIncludesDataset.includes(true)) {
                        stationsWithinTime.push(station);
                    }

                });

            }

        }

        return stationsWithinTime;

    }

    function resetAllFilters() {
        // updating states
        updateFilteredOrganizations([]);
        updateFilteredVariables([]);
        updateFilteredTimeframe([null,null]);
        updateFilteredLocation([]);

        // updating location filters
        hideDrawingToolControls();
        limitShapesOnMap(0);

        //updating points on map and filter tab
        const defaultStations = [...resourcesRef.current];
        updateTabDatasets(defaultStations);
        addStationsToMap(defaultStations);
    }

    function getStationsWithinLocation(stations, locationBounds) {

        const minCoords = locationBounds[0];
        const maxCoords = locationBounds[1];

        const datasetsWithinRectangle = stations.filter((dataset) => {
            return dataset.coordinates[1] > minCoords.lat && dataset.coordinates[0] > minCoords.lng && dataset.coordinates[1] < maxCoords.lat && dataset.coordinates[0] < maxCoords.lng;
        });

        return datasetsWithinRectangle;
    }

    async function filterByCriteria(baseCriteria, locationFilter = []) {

        const currentStations = [...resourcesRef.current];
        let filteredStations = [];

        for (let i = 0; i < baseCriteria.length; i++) {
            const filter = baseCriteria[i];
            if (i == 0) { // filter by organizations
                filteredStations = filter.length == 0 ? currentStations : currentStations.filter((station) => filter.findIndex((org) => org.id == station.organization.id) == -1 ? false : true );
            } else if (i == 1) { // filter by variables
                filteredStations = filter.length == 0 ? filteredStations : filteredStations.filter((station) => hasCommonStr(station.variables, filter));
            } else { // filter by time

                const noDateSelected = filter.filter((date) => date != null);
                const startDateValid = filter[0] != null ? filter[0] <= new moment() ? true : false : true; // checking that start data of time filter is <= current date
                if (noDateSelected.length > 0 && startDateValid) {
                    filteredStations = await filterStationsByTime(filteredStations, filter);
                }

            }
        }

        const locationRegion = locationFilter.length > 0 ? locationFilter : filteredLocation;

        if (locationRegion.length > 0) {
            filteredStations = getStationsWithinLocation(filteredStations, locationRegion);
        }

        return filteredStations;
    }

    async function updateFilteredStations(criteria) {

        updateLoadingDescription("Filtering");
        updateLoading(true);

        const filteredStations = await filterByCriteria(criteria);
        updateTabDatasets(filteredStations);

        updateFilterCriteria(criteria);
        addStationsToMap(filteredStations);

        updateLoading(false);
        updateLoadingDescription("Loading..");
    }

    function hasCommonStr(arr1, arr2) {
        const formatedArr1 = arr1.map((str) => {return str.toLowerCase()});
        const formatedArr2 = arr2.map((str) => {return str.toLowerCase()});
        const commonElements = formatedArr1.filter((value) => formatedArr2.includes(value));
        if (commonElements.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    function toggleDrawingTool() {

        setDiscoverDropdownStatus(false);
        // toggling the rectangle drawing tool
        const toolRef = mapCompRef.current.drawingToolRef().current;

        if (!toolRef._toolbars.draw._modes.hasOwnProperty('rectangle')) {
            mapCompRef.current.mapRef().addControl(toolRef);
        }
        toolRef._toolbars.draw._modes.rectangle.handler.enable();
    }

    function hideDrawingToolControls() {
        const toolRef = mapCompRef.current.drawingToolRef().current;
        mapCompRef.current.mapRef().removeControl(toolRef);
    }

    function limitShapesOnMap(minimumShapes) {
        const featureGroupShapes = mapCompRef.current.featureGroupRef().current;
        const drawnItems = featureGroupShapes._layers;

        if (Object.keys(drawnItems).length > minimumShapes) { // only allowing a minimum amount of shpaes to be drawn at a time
            Object.keys(drawnItems).forEach((layerId, index) => {
                if (index > 0) return;
                const layer = drawnItems[layerId];
                featureGroupShapes.removeLayer(layer);
            });
        }
    }

    const onMapShapeEditStart = (e) => {
		console.log("_onEditStart");
	};

    const onMapShapeCreated = (e) => {

        limitShapesOnMap(1);
        onMapShapeEditStop(e);
    };

	const onMapShapeEditStop = async (e) => {

        const type = e.layerType;

        if (type == 'rectangle') {
            const maxCoords = e.layer._bounds._northEast;
            const minCoords = e.layer._bounds._southWest;

            updateLoadingDescription("Filtering");
            updateLoading(true);

            const locationBound = [minCoords, maxCoords];
            const currentCriteria = [filteredOrganizations, filteredVariables, filteredTimeframe];
            const filteredStations = await filterByCriteria(currentCriteria, locationBound);

            updateFilteredLocation(locationBound);
            updateTabDatasets(filteredStations);
            addStationsToMap(filteredStations);
            openBottomPanel();

            updateLoading(false);
            updateLoadingDescription("Loading..");
        }
	};

	const onMapShapeEditDeleted = (e) => {
        hideDrawingToolControls();
	};

    const getColumnHeadings = () => {

        return [
            { field: 'title', headerName: 'Title', flex: 2 },
            { field: 'organization_name', headerName: 'Organization', flex: 1 },
            { field: 'coordinates', headerName: 'Location', flex: 1 },
            { field: 'station_type', headerName: 'Type', flex: 1, valueGetter: (params) => {return camelCaseToSentenceCase(params.value)}},
            { field: 'number_of_datasets', headerName: 'Number of Datasets', flex: 1 },
            { field: 'state', headerName: 'State', flex: 1, valueGetter: (params) => {return camelCaseToSentenceCase(params.value)}}
        ]
    }

    const customGridToolbar = () => {
        return (
            <GridToolbarContainer style={{height:35 ,justifyContent: "space-between", alignItems:'end', backgroundColor:'rgb(29, 162, 216)' }}>
                <h6 style={{color:'white'}}>Selected Stations</h6>
                <div>
                    <GridToolbarFilterButton style={{ color: "#FFF"}} />
                    <GridToolbarExport style={{ color: "#FFF"}} printOptions={{ disableToolbarButton: true }} />
                </div>
            </GridToolbarContainer>
        )
    }

    const BottomPanel = () => {
        return (
            <div>
                <div style={{justifyContent: "flex-end", display: "flex"}}>
                    <Fab onClick={closeBottomPanel} variant="extended" size="small" sx={[mapAnalysisToggleButton, {marginBottom: '5px', padding: '10px'} ]}>
                        Close
                    </Fab>
                </div>
                <Paper sx={bottomPanelStyle}>
                    <DataGrid
                        components={{ Toolbar: customGridToolbar }}
                        getRowId={(row) => row.id}
                        rowHeight={35}
                        headerHeight={28}
                        rows={currentTabDatasets}
                        onRowClick={(gridRow) => openDetailModal(gridRow.row)}
                        columns={getColumnHeadings()}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        checkboxSelection={false}
                        disableSelectionOnClick={false}
                        showCellRightBorder
                        disableColumnSelector
                        disableColumnMenu
                        editMode='row'
                        experimentalFeatures={{ newEditingApi: true, columnGrouping:true }}
                        sx={data_grid_styles}
                    />

                </Paper>
            </div>
        )
    }

    const NavItem = (props) => {

        return (
          <li className="nav-item">
            <Button style={nav_button} onClick={() => props.onClickMethod && props.onClickMethod()}>{props.menuName}</Button>
            {discoverDropdownActive && props.children}
          </li>
        );
    }

    return (

        <MapOuterDiv>

           <MapNav>
                <MapNavbarContainer>
                    <MapNavLogo to="/" >ODF Pilot</MapNavLogo>
                    <div style={{ display: "flex", justifyContent: "center", marginLeft: "50px", padding: '21px'}}>
                        <NavItem menuName="Discover" onClickMethod={() => setDiscoverDropdownStatus(!discoverDropdownActive)} >
                            <DiscoverDropdown
                                dropdownActive={setDiscoverDropdownStatus}
                                filterBy={updateFilteredStations}
                                resetFilters={resetAllFilters}
                                getCurrentFilters={fetchCurrentMenuSelections}
                                subMenu={discoverDropdownSubMenu}
                                setSubMenu={setDiscoverDropdownSubMenu}
                                menuHeight={discoverDropdownHeight}
                                setMenuHeight={setDiscoverDropdownHeight}
                                variableCollapsibles={discoverDropdownVariableMenu}
                                setVariableCollapsible={setDiscoverDropdownVariableSubMenu}
                                toggleDrawing={toggleDrawingTool}
                                toggleBottomTable={toggleBPanel}
                                orgOptions={allDatasetOrganizations}
                                variableOptions={allDatasetVariables}
                            />
                        </NavItem>
                        <NavItem menuName="Share" onClickMethod={() => toggleDataUploadModal()} />
                        <NavItem menuName="Manage" onClickMethod={() => toggleManageModal(!manageModalOpen)} />
                    </div>
                    <SettingsDropdown/>
                </MapNavbarContainer>
            </MapNav>

            <MapComponent ref={mapCompRef} shapeEditStart={onMapShapeEditStart} shapeCreated={onMapShapeCreated} shapeEditStop={onMapShapeEditStop} shapeDeleted={onMapShapeEditDeleted} />

            <Modal
                open={isLoading}
            >
                <Box sx={{ ...loaderStyle}}>
                    <CircularProgress />
                    <h2 style={loadingDescriptionStyle}>{loadingDescription}</h2>
                </Box>
            </Modal>

            <Modal open={detailedModalOpen} onClose={closeDetailModal}>
                <Box>
                    <DetailsModal station={currentDetailDataset} />
                </Box>
            </Modal>

            <Modal open={dataUploadModalOpen} onClose={closeDataUploadModal}>
                <Box>
                    <DataUploadModal />
                </Box>
            </Modal>

            <Modal open={manageModalOpen} onClose={closeManageModal}>
                <Box>
                    <ManageModal allStations={datasetResources} />
                </Box>
            </Modal>

            <div style={{ position: 'absolute', bottom: 0, left:0, maxWidth: '100%', zIndex: '1299', padding: '5px 5px'}}>
                { showBottomTab ? <BottomPanel /> : null}
            </div>

        </MapOuterDiv>
    )
}

export default MapPage;