import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { MainSection }  from '../components/MainSection';
import Footer from '../components/Footer';

const Home = () => {

    return (
        <>
            <Navbar/>
            <MainSection />
            <Footer />
        </>
    );
};

export default Home;