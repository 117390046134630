import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { getErddapMetadata } from '../../utils/apiRequestBuilder';


const detailedRiskModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
};

const details_header = { 
    backgroundColor: "rgb(29, 162, 216)",
    height: '40px',
    padding: '15px 10px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};

const metadataDiv = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'auto',
    height: '55vh'
};

const metadataTitle = {
    display: 'block',
    padding: '3px 0'
}

const metadataValue = {
    display: 'block'
}

const DetailsModal = (props) => {

    const [currentTab, updateCurrentTab] = React.useState("1");
    const [loadingDatasets, updateDatasetLoading] = React.useState(true);
    const [datasetsMetadata, updateErddapMetadata] = React.useState([]);

    const station = props.station;

    useEffect(() => {
        loadDatasetsMetadata();
    }, []);

    async function loadDatasetsMetadata() {
        const erddapDatasets = station.resources;

        for (let i=0; i < erddapDatasets.length; i++) {
            const urlSplitArr = erddapDatasets[i].url.split("/");

            const baseUrl = urlSplitArr[2];
            const erddapDatasetName = urlSplitArr.slice(-1)[0].split('.')[0]; // this may be temporary since ckan has an incorrect url

            erddapDatasets[i].displayMetadata = {} // to hold metadata we want to dsisplay

            const [request, options] = await getErddapMetadata(baseUrl, erddapDatasetName);
            const response = await fetch(request, options);
            if (response.status === 200) {
                const json = await response.json();
                const columnNames = json.table.columnNames;
                const rows = json.table.rows;

                const rowTypeIndex = columnNames.findIndex((column) => column == 'Row Type');
                const variableNameIndex = columnNames.findIndex((column) => column == 'Variable Name');
                const attributeNameIndex = columnNames.findIndex((column) => column == 'Attribute Name');
                const valueIndex = columnNames.findIndex((column) => column == 'Value');
                const variablesToExclude = ['time', 'longitude', 'latitude', 'stationid'];
                let time_coverage_start = '';
                let time_coverage_end = '';
                let station_id = '';
                let dataset_title = '';
                let dataset_source = '';
                const variables = [];
                const variable_names = [];
                rows.map((row) => {
                    if (row[rowTypeIndex] == 'variable' && !variablesToExclude.includes(row[variableNameIndex]) ) {
                        variables.push(row[variableNameIndex]);
                    }

                    if (variables.includes(row[variableNameIndex]) && !variablesToExclude.includes(row[valueIndex]) && (row[attributeNameIndex] == "standard_name" || row[attributeNameIndex] == "short_name") ){
                        if (!variable_names.includes(row[valueIndex].replaceAll('_', ' '))) {
                            variable_names.push(row[valueIndex].replaceAll('_', ' '));
                        }
                    }

                    if (row[attributeNameIndex] == 'time_coverage_start') { time_coverage_start = row[valueIndex]; }
                    if (row[attributeNameIndex] == 'time_coverage_end') { time_coverage_end = row[valueIndex]; }
                    if (row[attributeNameIndex] == 'stationid') { station_id = row[valueIndex]; }
                    if (row[attributeNameIndex] == 'title') { dataset_title = row[valueIndex]; }
                    if (row[attributeNameIndex] == 'source') { dataset_source = row[valueIndex]; }
                });

                erddapDatasets[i].displayMetadata.title = dataset_title;
                erddapDatasets[i].displayMetadata.source = dataset_source;
                erddapDatasets[i].displayMetadata.station_id = station_id;
                erddapDatasets[i].displayMetadata.time_coverage_start = time_coverage_start;
                erddapDatasets[i].displayMetadata.time_coverage_end = time_coverage_end;
                erddapDatasets[i].displayMetadata.erddap_metadata_url = erddapDatasets[i].url.replace('tabledap', 'info').replace('.html', '/index.html');
                erddapDatasets[i].displayMetadata.erddap_data_url = erddapDatasets[i].url;
                erddapDatasets[i].displayMetadata.variables = variable_names;
            }
        }

        updateErddapMetadata(erddapDatasets);
        updateDatasetLoading(false);
    }

    function handleTabChange(newTabEvent, newValue) {
        updateCurrentTab(newValue);
    }

    function camelCaseToSentenceCase(s) {
        if (s == null) {
          return;
        }
        const finalResult = typeof s === 'string' ? s.charAt(0).toUpperCase() + s.slice(1) : s;
        return finalResult;
    }

    function formatMetadataKey(value) {
        if (typeof value === 'string') {
            const camelCase = camelCaseToSentenceCase(value);
            const formatedValue = camelCase.replaceAll('_', ' ');
            return formatedValue;
        } else {
            return value;
        }
    }

    function formatMetadataValue(metadata_key) {

        const value = station[metadata_key];

        if (metadata_key.includes('metadata')) {
            return formatDate(value);
        } else if (metadata_key.includes('ckan')) {
            return ( <a href={value} target='_blank'>Metadata (CKAN)</a> );
        } else if (metadata_key == 'coordinates') {
            return value.join(', ');
        } else if (metadata_key.includes('keywords')) {
            const keywordsArr = value.split(',');
            return keywordsArr.map((keyword) => { return (<span key={keyword} style={{ padding: '5px', display: 'inline-block', backgroundColor: 'rgba(29, 162, 216, 0.2)', borderRadius: '10px', marginRight: '5px', marginBottom: '10px'}}> {formatMetadataKey(keyword)} </span>) });
        } else {
            return camelCaseToSentenceCase(value);
        }
    }

    function formatDatasetMetadata(key, value) {

        if (key.includes('time')) {
            return formatDate(value);
        } else if (key.includes('url')) {
            return ( <a href={value} target='_blank'> { key.includes('metadata') ? 'Metadata (Erddap)' : 'Data (Erddap)' }</a> );
        } else if (key.includes('variables')) {
            return value.map((variable) => { return (<span key={variable} style={{ padding: '5px', display: 'inline-block', backgroundColor: 'rgba(29, 162, 216, 0.2)', borderRadius: '10px', marginRight: '5px', marginBottom: '10px'}}> {formatMetadataKey(variable)} </span>) });
        } else {
            return camelCaseToSentenceCase(value);
        }
    }

    function formatDate(date) {
        const milliseconds = Date.parse(date);
        const formatedDate = new Date(milliseconds).toString();
        return formatedDate;
    }

    const renderDatasetInfoTab = () => {

        if (loadingDatasets) {
            return (
                <div style={{margin: 'auto', textAlign: 'center', position: 'absolute', top: '40%', left: '40%' }}>
                    <CircularProgress />
                    <Typography variant="body1">Fetching Metadata</Typography>
                </div>
            )

        } else {
            return datasetsMetadata.map((metadata) => {
                return (
                    <div key={metadata.id} style={{ marginBottom: '15px' }}>
                        <Typography variant="overline" style={{'fontWeight': 'bold'}}>Title: {metadata.displayMetadata.title}</Typography>
                        <div style={{ marginTop: '15px', marginLeft: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {Object.keys(metadata.displayMetadata).map( (displayKey) => {
                                return (
                                    <div key={displayKey} style={{ width: '25%', padding: '5px', marginTop: '5px' }}>
                                        <span style={metadataTitle}><Typography variant='subtitle2'>{formatMetadataKey(displayKey)}</Typography></span>
                                        <span style={metadataValue}><Typography style={{fontWeight: 'bold'}} variant='caption'>{ formatDatasetMetadata(displayKey, metadata.displayMetadata[displayKey])}</Typography></span>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ height: '0.5px', background: 'black' }}></div>
                    </div>
                )
            })
        }
    }

    const StationInfoTab = () => {
        const metadataToDisplay = ['name', 'title', 'coordinates', 'station_type', 'number_of_datasets', 'organization_name', 'notes', 'metadata_created', 'metadata_modified', 'state', 'ckan'];

        return metadataToDisplay.map((metadataKey) => {
            return (
                <div key={metadataKey} style={{ width: '50%', padding: '5px', marginTop: '5px' }}>
                    <span style={metadataTitle}><Typography variant='overline'>{formatMetadataKey(metadataKey)}</Typography></span>
                    <span style={metadataValue}><Typography style={{fontWeight: 'bold'}} variant='body2'>{formatMetadataValue(metadataKey)}</Typography></span>
                </div>
            )
        });
    }

    return (
        <div style={detailedRiskModalStyle}>
            <Paper sx={{width: '70vw', height: '70vh'}}>
                <div style={details_header}>
                    <Typography variant='h6' style={{ color: "#fff"}}>{station.title}</Typography>
                </div>

                <TabContext value={currentTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="detailstab">
                            <Tab label="Datasets" value="1" />
                            <Tab label="Station" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1"style={{display:'flex', flexDirection: 'column', overflow: 'auto', height: '85%'}}>{renderDatasetInfoTab()}</TabPanel>
                    <TabPanel value="2"><div style={metadataDiv}> <StationInfoTab /> </div></TabPanel>
                </TabContext>
            </Paper>
        </div>
    )

}

export default DetailsModal;