const baseServerRoute = process.env.REACT_APP_API_URL;
const { default: authService } = require("./AuthenticationService");

async function getHeader() {
    const token = await authService.getAccessToken().then(function (token) { return token; });
    return new Headers({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }); // eslint-disable-line no-undef
}

export async function getAllDatasetsResources() {
    const headers = await getHeader();
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        cache: 'default'
    };
    const request = new Request(`${baseServerRoute}/ckan/allStationResources`); // eslint-disable-line no-undef
    return [request, options];
}

export async function getErddapMetadata(baseUrl, dataset) {
    const headers = await getHeader();
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        cache: 'default'
    };
    const request = new Request(`${baseServerRoute}/erddap/datasetInfo/${baseUrl}/${dataset}`); // eslint-disable-line no-undef
    return [request, options];
}

export async function getErddapDatasetsWithinTimeRange(baseUrl, startTime, endTime) {
    const headers = await getHeader();
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        cache: 'default'
    };
    const request = new Request(`${baseServerRoute}/erddap/datasetTimeSearch/${baseUrl}/${startTime}/${endTime}`); // eslint-disable-line no-undef
    return [request, options];
}