import React, {useState, useRef, useEffect,} from 'react';
import { MainContainer, MainBg, MainContent, MainH1, MainP, MainBtnWrapper, ArrowForward, ArrowRight } from './MainElements';
import { Button } from '../ButtonElement';
import { animateScroll as scroll } from 'react-scroll';
import { MapContainer, TileLayer, WMSTileLayer } from 'react-leaflet';
import authService from '../../utils/AuthenticationService';

const MainSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    const toggleBottom = () => {
        scroll.scrollToBottom();
    };

    const goToSignIn = () => authService.login();


    //demo map code
    const baseLayerUrlList = [
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
        "https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}"
    ];
    // 
    const topoWmsUrl = 'https://ows.terrestris.de/osm/service?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetCapabilities';
    const sistemasURL = 'http://sistemas.gt4w.com.br/geoserver/processo_seletivo/wms';

    const demoMapConfig = { center: { lat: 47.57, lng: -52.865 }, zoom: 9 };
    
    return (
        <MainContainer id='home'>
            <MainBg>
            <MapContainer 
                center={demoMapConfig.center}
                zoom={demoMapConfig.zoom} 
                scrollWheelZoom={false}>

                <WMSTileLayer id='wmsLayer1'
                    url={'https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi'}
                    opacity={0.5}
                      >
                </WMSTileLayer>

                <TileLayer 
                    url={baseLayerUrlList[0]}
                    opacity={0.5}>
                </TileLayer>

                

            </MapContainer>
            </MainBg>
            <MainContent>
                <MainH1>ODF Open Data Framework</MainH1>
                <MainP>
                    The Open Offshore, Newfoundland and Labrador
                </MainP>
                <MainBtnWrapper>
                        <Button
                            to=""
                            onClick={goToSignIn}
                            onMouseEnter={onHover} 
                            onMouseLeave={onHover} 
                            primary='true'
                            offset={-60}>
                                Log In {hover ? <ArrowRight /> : <ArrowForward />}
                        </Button>
                </MainBtnWrapper>
            </MainContent>
        </MainContainer>   
    )
};


export { MainSection };

