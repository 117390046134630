import React, {useEffect, useState, useRef} from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { FaUpload, FaRegFileAlt, FaTrashAlt, FaExclamationCircle, FaCheckCircle, FaCheck, FaTimes } from "react-icons/fa";
import './modal.css';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
};


const DataUploadModal = (props) => {

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [validationPassed, updateValidationStatus] = useState(null);
    const [validationMessages, setValidationMessages] = useState([]);
    const selectFileInputRef = useRef();

    const handleFileDropOver = (event) => {
        event.preventDefault();
    }

    const handleFileDrop = (event) => {
        event.preventDefault();
        if (uploadedFiles.length == 0) {
            const currentFiles = [...uploadedFiles];
            Array.from(event.dataTransfer.files).map((file) => currentFiles.push(file));
            setUploadedFiles(currentFiles);
            validateUpload(currentFiles[0]); // for now we only allow one file to be uploaded at a time
        }
    }

    function validateUpload(file) {

        const requiredFields = ['name', 'title', 'owner_org', 'resources', 'type', 'spatial'];
        const recommendedFields = ['description', 'private', 'author', 'author_email', 'extras'];
        const requiredResourceFields = ['url', 'name', 'format', 'description'];
        const errorMessages = [];
        const successMessages = [];

        const fileReader = new FileReader();
        if (file.type == "application/json") {
            fileReader.readAsText(file);
            fileReader.onload = () => {
                const jsonFile = JSON.parse(fileReader.result);
                // perform validation
                const missingRequiredFields = [];
                requiredFields.forEach((field) => { if (!jsonFile.hasOwnProperty(field)) { missingRequiredFields.push(field); }; });
                if (missingRequiredFields.length > 0) {
                    const errorMessage = 'Missing required parameters ' + missingRequiredFields.join();
                    errorMessages.push(errorMessage);
                } else {
                    const successMessage = 'All required parameters provided';
                    successMessages.push(successMessage);
                }

                if (jsonFile.hasOwnProperty('resources')) {

                    const missingResourceFields = [];

                    jsonFile.resources.forEach((resource) => {
                        requiredResourceFields.forEach((field) => { if (!resource.hasOwnProperty(field) && !missingResourceFields.includes(field)) { missingResourceFields.push(field)}; });
                    });

                    if (missingResourceFields.length > 0) {
                        const errorMessage = 'Missing required resource parameters ' + missingResourceFields.join();
                        errorMessages.push(errorMessage);
                    } else {
                        const successMessage = 'All required resources attributes found.';
                        successMessages.push(successMessage);
                    }

                } else {
                    const errorMessage = 'No resources provided.';
                    errorMessages.push(errorMessage);
                }

                if (jsonFile.hasOwnProperty('spatial')) {
                    const successMessage = 'Spatial attributes found.';
                    successMessages.push(successMessage);
                } else {
                    const errorMessage = 'No spatial resources provided.';
                    errorMessages.push(errorMessage);
                }

                if (errorMessages.length > 0) {
                    setValidationMessages(errorMessages);
                    updateValidationStatus(false);
                } else {
                    setValidationMessages(successMessages);
                    updateValidationStatus(true);
                }


            }
        } else {
            setValidationMessages(['No Validation for file type.']);
            updateValidationStatus(false);
        }

    }

    function updateSelectedFiles(event) {

        const currentFiles = [...uploadedFiles];
        Array.from(event.target.files).map((file) => currentFiles.push(file));

        setUploadedFiles(currentFiles);
        validateUpload(currentFiles[0]); // for now we only allow one file to be uploaded at a time
        event.target.value = null;
    }

    function deleteFile(fileIndex) {
        setUploadedFiles((currentFiles) => currentFiles.filter((file, index) => { return index != fileIndex; }) );
    }

    return (
        <div style={modalStyle}>
            <Paper sx={{width: '50vw', height: '70vh'}}>

                <div className='modal-container'>

                    <Typography variant="h5">Upload Metadata </Typography>

                    <div className={`upload-container ${uploadedFiles.length > 0 ? "upload-greyed" : "upload-default"}`}
                        onDragOver={handleFileDropOver}
                        onDrop={handleFileDrop}
                    >
                        <FaUpload />
                        <p> Drag & Drop or <a href={uploadedFiles.length > 0 ? null : '#'} onClick={() => uploadedFiles.length > 0 ? null : selectFileInputRef.current.click()}>Select file</a> to upload</p>
                        <Typography variant="subtitle1">XML, CSV or JSON</Typography>
                        <input type="file" onChange={(event) => updateSelectedFiles(event)} hidden accept="application/json,.xml,.csv" ref={selectFileInputRef} />
                    </div>

                    <div className='file-uploaded-section'>
                        {uploadedFiles.map((uploadedFile, fileIndex) => {
                            return (
                                <li key={fileIndex} className='file-uploaded-row'>
                                    <FaRegFileAlt style={{ fontSize: '22px'}} />
                                    <Typography variant='body1'>{uploadedFile.name}</Typography>
                                    <Button variant="text" onClick={() => deleteFile(fileIndex)}><FaTrashAlt style={{ fontSize: '17px', marginLeft: 'auto' }} /></Button>
                                </li>
                            )
                        })}
                    </div>

                    {validationPassed != null && uploadedFiles.length > 0 && (
                        <div className={`validation-box ${ validationPassed ? "v-box-success" : "v-box-error"}` }>
                            <div className={`validation-header ${validationPassed ? "header-correct" : "header-error"}`}>
                                { validationPassed ? <FaCheckCircle /> : <FaExclamationCircle /> }
                                <Typography variant='h6'> Validation</Typography>
                            </div>

                            <div className='validation-messages-box'>
                                {validationMessages.map((message, mIndex) => {
                                    return (
                                        <li key={mIndex} className={`validation-message ${ validationPassed ? "validation-success" : "validation-error"}` }>
                                            { validationPassed ? <FaCheck /> : <FaTimes /> }
                                            <Typography variant='caption1'>{message}</Typography>
                                        </li>
                                    )
                                })}

                            </div>
                        </div>
                        )
                    }


                    <Button disabled={!validationPassed || uploadedFiles.length == 0} variant="contained">Submit</Button>


                </div>

            </Paper>
        </div>
    )

}


export default DataUploadModal;