import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { MapContainer, TileLayer, LayersControl, FeatureGroup } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import baseMapProvider from './baseMapProvider';
import "leaflet/dist/leaflet.css";
import { MapMainContainer } from './MapElements';
import L from "leaflet";

const MapBaseLayers = () => {
    const layers = baseMapProvider.map((layer, index) => {
       return (
            <LayersControl.BaseLayer checked={layer.default ? true : false} key={index} name={layer.name}>
                <TileLayer
                    attribution={layer.attribution}
                    url={layer.url}
                />
            </LayersControl.BaseLayer>
       )
    });

   return layers;
}

export const MapComponent = forwardRef((props, ref) => {
    const [map, setMap] = useState(null);
    const maxBounds = [[-90, -180], [90, 180]];
    const [mapFGShapes, updateFGMapShapes] = useState(null);

    const mapFGShapesRef = useRef();
    mapFGShapesRef.current = mapFGShapes;

    const drawToolRef = useRef();

    useImperativeHandle(ref, () => ({
        mapRef() {
            return map;
        },
        drawingToolRef() {
            return drawToolRef;
        },
        featureGroupRef() {
            return mapFGShapesRef;
        }
    }));

    function setCursorCoordinatesDisplay(map) {

        const coordinatesTooltip = new L.tooltip({direction: 'left'});

        map.target.addEventListener("mousemove", (e) => {
            if (coordinatesTooltip.isOpen()) {
                coordinatesTooltip.close();
            }

            const coordinates = e.latlng;
            let latitude = coordinates.lat.toFixed(2);
            let longitude = coordinates.lng.toFixed(2);

            if (longitude < -180) {
                longitude += 360;
            } else if (longitude > 180) {
                longitude -= 360;
            }

            const locationInfo = 'Lat: ' + latitude + ', Lon:' + longitude;
            coordinatesTooltip.setLatLng(e.latlng).setContent(locationInfo).addTo(map.target);
        });

        map.target.addEventListener("mouseout", (e) => {
            coordinatesTooltip.close();
        });
    }

    function setDrawToolRef(drawControl) {
        drawToolRef.current = drawControl;
    }

    const onFeatureGroupReady = reactFeatureGroupRef => {
        updateFGMapShapes(reactFeatureGroupRef);
    };

    return (
            <MapMainContainer
                center={[51.0, 19.0]}
                style={{ width: '100%', position: 'relative', display: 'flex'}}
                zoom={3}
                minZoom={3}
                maxZoom={15}
                maxBounds={maxBounds}
                whenReady={(map) => {setCursorCoordinatesDisplay(map);}}
                ref={setMap}
            >

                <LayersControl position="topright">
                    <MapBaseLayers />
                </LayersControl>

                <FeatureGroup
                    ref={featureGroupRef => {
                        onFeatureGroupReady(featureGroupRef);
                    }}
                >
                    <EditControl
                        position='bottomright'
                        onEdited={props.shapeEditStart}
                        onCreated={props.shapeCreated}
                        onDeleted={props.shapeDeleted}
                        onMounted={setDrawToolRef}
                        draw={{
                            circle: false,
                            marker: false,
                            polyline: false,
                            polygon: false,
                            circlemarker: false
                        }}
                    />
                </FeatureGroup>



            </MapMainContainer>
    )
})
